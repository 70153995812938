import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'gatsby';
import iconExpand from '../../images/icon-expand.png';
import iconArrow from '../../images/icon-arrow-yellow-right.png';

export const AccordionContainer = styled(Accordion)``;

export const ButtonContainer = styled.div`
  margin-top: 40px;
`;

export const AccordionItem = styled(Accordion.Item)`
  :last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
  @media print {
    .accordion-collapse {
      display: block !important;
      height: auto !important;
    }
  }
`;

export const ListContainer = styled.ul`
  padding: 0;
  margin: 0 0 30px 0;
`;

export const ListText = styled.li`
  background: url(${iconArrow}) no-repeat 0 9px;
  background-size: 10px auto;
  list-style: none;
  color: #666;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  padding: 0 0 15px 30px;
  margin: 0;
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  button {
    border-bottom: 1px solid #e5e5e5;
    font-weight: normal !important;
    font-family: 'HelveticaNeue-Light', arial, sans-serif;
    font-size: 26px !important;
    line-height: 32px !important;
    padding: 28px 65px 25px 0;
    color: #48b4f1;
    :hover {
      text-decoration: none;
      color: #f7991c;
    }
    ::after {
      display: block;
      position: absolute;
      right: 35px;
      height: 20px;
      width: 20px;
      background: #fff url(${iconExpand}) no-repeat center right;
      background-size: 100% auto;
    }
    @media (max-width: 1399px) {
      font-size: 22px !important;
      line-height: 26px !important;
    }
    @media (max-width: 991px) {
      ::after {
        right: 0px;
      }
    }
    @media (max-width: 991px) {
      padding: 28px 45px 25px 0;
    }
    @media (max-width: 767px) {
      font-size: 20px !important;
      line-height: 24px !important;
    }
  }
  .accordion-button: not(.collapsed) {
    background-color: #fff;
    color: #f7991c;
    border-bottom: none !important;
    ::after {
      display: block;
      position: absolute;
      right: 35px;
      height: 20px;
      width: 20px;
      background: #fff url(${iconExpand}) no-repeat center right;
      background-size: 100% auto;
      transform: rotate(45deg);
    }
    @media (max-width: 991px) {
      ::after {
        right: 0px;
      }
    }
  }
  .accordion-button:focus {
    border-color: none;
    box-shadow: none;
  }
  .accordion-button:active {
    color: #037ec4 !important;
  }
`;

export const AccordionBody = styled(Accordion.Body)`
  padding: 15px 0 !important;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

export const LinkStyle = styled.a`
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  background-size: 7px auto;
  text-decoration: none;
  text-align: left;
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
  transition: background-position 0.15s ease-out;
  :hover {
    text-decoration: none;
    top: 0;
    color: #f7991c;
    box-shadow: none;
  }
`;

export const LinkStyleInternal = styled(Link)`
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #9fb577;
  background-size: 7px auto;
  text-decoration: none;
  text-align: left;
  transition: background-position 0.15s ease-out;
  :hover {
    color: #00457c;
  }
`;

export const AnchorButton = styled.a`
  padding: 16px 85px 14px 15px;
  margin: 0 5px 30px 0;
  text-decoration: none;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase !important;
  text-align: left;
  color: #037ec4;
  background: #ebf5fa url(${iconExpand}) no-repeat 95% center;
  &:hover {
    background: #ebf5fa url(${iconExpand}) no-repeat 90% center;
    transition: background-position 0.15s ease-out;
    border: none;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;
export const AnchorButtonInternal = styled(Link)`
  padding: 16px 85px 14px 15px;
  margin: 0 5px 30px 0;
  text-decoration: none;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase !important;
  text-align: left;
  color: #037ec4;
  background: #ebf5fa url(${iconExpand}) no-repeat 95% center;
  &:hover {
    background: #ebf5fa url(${iconExpand}) no-repeat 90% center;
    transition: background-position 0.15s ease-out;
    border: none;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;

export const ParaText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  position: relative;
  padding: 0;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    line-height: 22px;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;

export const DescriptionText = styled(ParaText)`
  padding: 0 20px;
`;

export const Header2 = styled.h2`
  margin-bottom: 10px;
  padding: 0 20px;
  position: relative;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  font-weight: normal;
  font-style: normal;
  margin: 0 0 35px 0;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
  }
  @media (max-width: 991px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const Header2Center = styled.h2`
  text-align: center;
  margin-bottom: 35px;
  position: relative;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  @media (max-width: 1439px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const ButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 15px 35px;
  margin-right: 10px;
  font-family: 'MrAlex', sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: bold;
  color: #00a6e8;
  border: 2px solid #00a6e8;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  @media (max-width: 1199px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 25px;
  }
  :hover {
    top: -5px;
    color: #f7991c;
    border-color: #f7991c;
    box-shadow: 0 5px 0 0 #f7991c;
  }
`;
export const ColumnsHalf = styled.div`
  padding: 0px 20px;
  width: 110%;
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
    padding: 0px;
  }
  *:last-child {
    margin-bottom: 0;
  }
`;
export const ColumnsHalfRight = styled.div`
  float: right;
  width: 50%;
  padding: 0px 20px;
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
    padding: 0px;
    ul:first-child {
      margin-top: -30px;
    }
  }
  *:last-child {
    margin-bottom: 0;
  }
`;
export const Body = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 40px 90px;
  @media (max-width: 991px) {
    padding: 35px;
    width: 100%;
    display: block;
  }
`;
export const ImageStyle = styled.img`
  width: 100%;
  height: auto;
  margin: 0;
`;
export const SpanStyle = styled.span`
  position: relative;
  top: -200px;
  @media (max-width: 991px) {
    top: -50px;
  }
`;

export const OrderedListContainer = styled.ol`
  margin: 0 0 30px 0;
  padding: 0 !important;
  list-style-type: none;
  counter-reset: standard-counter;
  @media (max-width: 767px) {
    padding-left: 50px;
  }
  li {
    background: none;
    position: relative;
    margin: 0;
    padding: 15px 0 15px 65px !important;
    ::before {
      content: counter(standard-counter);
      counter-increment: standard-counter;
      position: absolute;
      left: 0;
      top: 12px;
      width: 40px;
      height: 40px;
      text-align: center;
      padding: 6px 0 0 0;
      font-family: 'MrAlex', arial, sans-serif;
      font-size: 30px;
      line-height: 30px;
      color: #ffc905;
      border: 2px solid #ffc905;
      border-radius: 50%;
      @media (max-width: 767px) {
        top: 11px;
        width: 30px;
        height: 30px;
        padding: 6px 0 0 0;
        font-size: 20px;
        line-height: 20px;
      }
    }
    a {
      text-decoration: none;
      color: #00a6e8;
      transition: 0.25s ease-out;
      padding: 0;
      margin-right: 0;
      margin-bottom: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
      font-weight: inherit;
      color: #00a6e8;
      border: none;
      box-shadow: none;
      cursor: pointer;
      text-decoration: none;
      :hover {
        text-decoration: none;
        top: 0;
        color: #f7991c;
        box-shadow: none;
      }
    }
  }
`;
